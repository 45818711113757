html {
  overflow-x:hidden;
}
.mobilenavbar {
  background-color: #fff;
  margin: 0;
  border-bottom: 1px solid #eee;
  z-index: 2000;
  position: relative;
  &:after {
    clear: both;
  }
  .list-inline {
    height: $navbar-height;
    margin-bottom: 0;
  }
  .list-logo {

  }
  .list-profile {
    padding-left: 0;
    padding-right: 0;
  }
  .list-menu {
    a {
      color: #000;
    }
    padding-top: 7px;
    padding-right: 7px;
  }
}

#burgerIcon {

}

.menuSito {
  background-color: $brand-primary;
	// width: 100%;
  // // height: 200px;
  // height: 100%;
	// margin-top: 0px;
	// margin-bottom: 0px;
	// border: 1px solid #fff;
	// display: inline-block;
  // top: $navbar-height;
	// position: absolute;
	// left: 0px;
	// z-index: 100;
	// display: none;
  z-index: 100;
	position: absolute;
  width: 100vw;
	height: 100vh;
  left: 0;
  top: 0;
  box-sizing:border-box;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
  display: none;
}
.menuBox {
  position: absolute;
  width: 50%;
  height: 50%;
  display: table;
  // -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), inset 0 0 50px rgba(0, 0, 0, 0.1);
	// -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), inset 0 0 50px rgba(0, 0, 0, 0.1);
	// box-shadow: 0 0 5px rgba(0, 0, 0, 0.2), inset 0 0 50px rgba(0, 0, 0, 0.1);
  span {
    display: table-cell;
    vertical-align: middle;
  }
  text-align: center;
  &:nth-child(1) {
    top: 0;
    left: 0;
    border-right: 1px solid #333;
    border-bottom: 1px solid #333;
  }
  &:nth-child(2) {
    top: 0;
    left: 50%;
    border-bottom: 1px solid #333;
  }
  &:nth-child(3) {
    top: 50%;
    left: 0;
    border-right: 1px solid #333;
  }
  &:nth-child(4) {
    top: 50%;
    left: 50%;
  }
  // position: relative;
  // width: 50%;
  // height: 50%;
  //
  // // padding-top: 40%;
  //
  // float: left;
  // text-align: center;
  // box-sizing:border-box;
  // -moz-box-sizing:border-box;
  // -webkit-box-sizing:border-box;
  // &:nth-child(odd) {
  //   border-right: 1px solid #000;
  // }
  // border-bottom: 1px solid #000;
  color: #fff;
  &:hover {
    color: #fff;
  }


}
.box_menuSito {
	height: 100px;
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 20px;
	border: 1px solid rgba(61, 106, 124, 0.2);
	text-align: center;
	color: white;
  &:hover {
    color: #fff
  }
}
.titlepage {
  background-color: $brand-primary;
  color: #fff;
  margin-bottom: 20px;
  &.row {
    margin-left: 0;
    margin-right: 0;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .icona_recap {

    height: 100px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 20px;
    text-align: center;
  }

  .testo_recap {
    font-weight: 300;
    height: 100px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 28px;
    text-align: left;
    font-size: 1.2em;
    b {
      font-weight: 700;
    }
  }
}

.panel-title > a > .badge {
    float: right;
}

.bigredbutton {
  margin-bottom: 1em;
  // .col-xs-12 {
  //   padding-left: 0;
  //   padding-right: 0;
  // }
  // @media (min-width: $screen-xs-min) {
  //   .col-xs-12 {
  //     padding-left: $grid-gutter-width / 2;
  //     padding-right: $grid-gutter-width / 2;
  //   }
  // }

  .btn {
    text-transform: uppercase;
  }
}

.testo_barra_step {
  font-size: 1.2em;
  margin-bottom: 2em;
  .header {
     width: 100%;
     text-align: center;
    //  border-bottom: 1px solid #ccc;
     line-height: 0.1em;
     margin: 10px 0 20px;
     .finish-icon {
       background:#fff;
       padding:0 10px;
     }
  }

.ticket-new-priority {
  label {
    width: 25%;
  }
}

  // .header {
  //   text-align: center;
  //   .separator {
  //     display: inline-block;
  //     width: 160px;
  //     &:before {
  //       content: " ";
  //     }
  //     border-top: 1px solid #ccc;
  //   }
  //   .finish-icon {
  //     color: #ccc;
  //   }
  // }
}

.button-margin-left {
  border-left: 1px solid #fff;
}
.navbar-header-mobile {
  margin-right: 12px !important;
}
.nhm {
  & > li {
    height: $navbar-height;
    //padding-top: 9px;
  }
  .mobile-navbar-profile {
    padding-top: 7px;
    padding-left: 20px;
    padding-right: 10px;
  }
}
.navbar-header-mobile-menu {
  width: 40px !important;
  margin-right: 0px !important;
  li {
    text-align: center;
    width: 100%;
  }
}

.material-list-group {
  a {
    .icon-material {
      color: #fff;
    }

    &:hover {
      .icon-material {
        color: $gray-dark;
      }
    }

  }
}


.breadcrumb-mobile {
  background-color: transparent;
  text-transform: uppercase;
  margin-bottom: 0;
  .current {
    color: #000;
    font-weight: bold;
    font-size: 1em;
  }
  .backbutton {
    width: 100%;
    text-align: right;
    a {
      font-size: 0.8em;
      color: $gray-light;
    }
  }
}
.panel-group .panel {
  margin-bottom: 10px;
}
.panel-default {
  border-color: #fff;
  padding: 5px 10px;
  margin-bottom: 10px;
  .panel-heading {
    padding-left: 0;
    background-color: transparent;
  }
  .panel-collapse {
    a.list-group-item {
      padding-left: 0;
    }
  }
}
.list-group-item {
  .badge {
    width: 32px;
    border-radius: 0;
    margin-right: 5px;
    &.open {
      background-color: #962a8b;
    }
    &.approved {
      background-color: #00aade;
    }
    &.processing {
      background-color: #86c54e;
    }
    &.wait {
      background-color: #962a8b;
    }
    &.closed {
      background-color: #86c54e;
    }
    &.notapproved {
      background-color: #fe9239;
    }
    &.not_approved {
      background-color: #fe9239;
    }
    &.over_sla {
      background-color: #f4645f;
    }
    &.percent {
      width: 50px;
    }
  }
}
.list-group-item > .badge {
  float: left;
}
.list-group-item {
  padding-left: 0;
}
.panel-heading .accordion-toggle:after {
    /* symbol for "opening" panels */
    font-family: 'Glyphicons Halflings';  /* essential for enabling glyphicon */
    content: "\e014";    /* adjust as needed, taken from bootstrap.css */
    float: right;        /* adjust as needed */
    color: grey;         /* adjust as needed */
}
.panel-heading .accordion-toggle.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\e080";    /* adjust as needed, taken from bootstrap.css */
}



.stepform {
  .thumbnail {
    background-color: transparent;
    border: 0;
    a {
      color: #999;
    }
  }
}


.mobile-container {
  @include container-fixed();
  padding-left: 0;
  padding-right: 0;
  margin: 0;

  @media (min-width: $screen-sm-min) {
    // width: $container-sm;
    padding-left:  ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
    margin: 0 auto;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
    margin: 0 auto;
  }
}

ul.mobile-ticket-headertable {
  margin: 0;
  background: #eee;
  li.mobile-ticket-status-filter {
    padding-top: 7px;
  }
}

li.mobile-ticket-status {
  color: #fff;
  text-transform: uppercase;
  padding: 15px 30px;

  span {
    border-left: 1px solid #fff;
    padding-left: 7px;
    margin-left: 7px;
  }
  &.status {
    background: $brand-success;
  }
  &.status-open {
    background: $gray-light;
  }
  &.status-not_approved {
    background: $brand-danger;
  }
}

.create-ticket-step-label-container {
  margin-top: 2em;
}
.create-ticket-steps-label {
    padding-left: 2em;
    color: #fff;
    font-size: 16px;
    display: none;
    &:first-child {
      display: inline-block;
    }
}
