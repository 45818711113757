.timeline {
  list-style: none;
}
.timeline > li {
  margin-bottom: 60px;
}

/* for Desktop */
@media ( min-width : 640px ){
  .timeline > li {
    overflow: hidden;
    margin: 0;
    position: relative;
  }
  .timeline-date {
    width: 110px;
    float: left;
    margin-top: 20px;
  }
  .timeline-content {
    width: 75%;
    float: left;
    border-left: 3px #e5e5d1 solid;
    padding-left: 30px;
  }
  .timeline-content:before {
    content: '';
    width: 12px;
    height: 12px;
    background: #6fc173;
    position: absolute;
    left: 106px;
    top: 24px;
    border-radius: 100%;
  }
}



.nav-justified {
  > li {
    display: table-cell;
    width: 1%;
    > a {
      margin-bottom: 0;
    }
  }
}



.hortime {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  border: 2px solid #fff;
  li {
    a {
      &:hover {
        background-color: transparent;
      }
    }
  }
  li.disabled {
    a {
      color: #fff !important;
      &:hover {
        background-color: transparent;
      }
    }
  }
}
