@import "../scss/smartadmin-production";
@import "../scss/smartadmin-production-plugins";
@import "../scss/smartadmin-skins";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";
@import "timeline";

html {
  position: relative;
  min-height: 100%;
}
body {
  // margin-top: $navbar-height + 20;
  margin-bottom: $navbar-height + 40;

  &.login-page {
    background-image: url('../../img/background_login.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 3em;
  }
}
.navbar-brand {
  padding: 20px 13px;
  img {
    width: 104px;
    height: 23px;
    padding: 0;
  }
  @media (min-width: $screen-xs-min) {
    padding: 14px 13px;
    img {
      width: 136px;
      height: 30px;
      padding: 0;
    }
  }
}
.well {
  background: #fff;
}

.navbar-profile {
  background-color: #f7f7f7;
  background-image: url('../../img/header_profile_background.png');
  background-repeat: no-repeat;
  background-position: center;
  a {
    color: #000;
  }
}

.feedback {
  padding: 15px;
}
td {
  vertical-align: middle;
}
.priority-container {
  position: relative;
  min-width: 20px;
  width: 20px;
  padding: 0 !important;
}
.priority-cell {
  position: absolute;
  height: 25%;
  width: 100%;
  text-align: center;
  color: $gray-light;
  font-size: $tooltip-font-size;
  opacity: .4;
  &.active {
    color: #fff;
    opacity: 1;
  }
}

$priority-1-background: #ea454b;
$priority-2-background: #f15a24;
$priority-3-background: #f8931f;
$priority-4-background: #fbb03b;

.priority-4 {
  top: 75%;
  background-color: #f8f8f8;
  &.active {
    background-color: $priority-4-background;
  }
}
.priority-3 {
  top: 50%;
  background-color: #f1f1f1;
  &.active {
    background-color: $priority-3-background;
  }
}
.priority-2 {
  top: 25%;
  background-color: #e3e3e3;
  &.active {
    background-color: $priority-2-background;
  }
}
.priority-1 {
  top: 0;
  background-color: #d5d5d5;
  &.active {
    background-color: $priority-1-background;
  }
}

.footer {
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  height: $navbar-height + 30;
  font-size: 12px;
  border-top: 1px solid #cecece;
  background: #000;
  text-align: center;
  color: #fff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  .container {
    margin-top: 2em;
  }
}

.card {
  background: #fff;
  border: 1px solid #eee;
  &.cronologia {
    margin-top: 2em;
  }
}
.ticket-store-signboard {
  background: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 15px;
}
.ticket-store-details {
  color: $gray-light;
  font-size: 16px;
  padding: 10px;
  border-top: 1px solid #eee;
  &--sla {
    @extend .ticket-store-details;
    margin-top: 1em;
  }
  i {
    margin-right: 10px;
  }
}

.chart {
  margin-top: 20px;
}

.percentage, .label {
    text-align: center;
    color: #333;
    font-weight: 100;
    font-size: .7em;
    // margin-bottom: 0.4em;
}
.easyPieChart {
    position: relative;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    canvas {
      position: absolute;
      top: 0;
      left: 0;
    }
}

.admin-panel-heading {
  height: 80px;
  line-height: 80px;
  padding: 0;
  .panel-title {
    line-height: 80px;
    padding: 0 15px;
  }
}

$big-circle-size: 100px;
.bigcircle {
  position: relative;
  width: $big-circle-size;
  height: $big-circle-size;
  line-height: $big-circle-size;
  text-align: center;
  border-radius: 50%;
  margin: 0 auto;
  &.priority-1 {
    background: $priority-1-background;
  }
  &.priority-2 {
    background: $priority-2-background;
  }
  &.priority-3 {
    background: $priority-3-background;
  }
  &.priority-4 {
    background: $priority-4-background;
  }
  &.status {
    background: $brand-success;
  }
  &.status-1 {
    background: $gray-light;
  }
  &.status-6 {
    background: $brand-danger;
  }
  .counter {
    position: absolute;
    top: 10px;
    left: 10px;
    border: 1px solid #fff;
    color: #fff;
    font-size: 3em;
    font-weight: 100;
    width: $big-circle-size - 20px;
    height: $big-circle-size - 20px;
    line-height: $big-circle-size - 20px;
    text-align: center;
    border-radius: 50%;
  }
}
.priority-label {
  font-weight: bold;
  font-size: 1.2em;
  background-color: inherit;
  text-align: center;
  margin-top: 1em;
  &.priority-1 {
    color: $priority-1-background;
  }
  &.priority-2 {
    color: $priority-2-background;
  }
  &.priority-3 {
    color: $priority-3-background;
  }
  &.priority-4 {
    color: $priority-4-background;
  }
  &.status {
    color: $brand-success;
  }
  &.status-1 {
    color: $gray-light;
  }
  &.status-6 {
    color: $brand-danger;
  }
}

.btn-ticket-approval {
  width: 150px;
  margin-left: 2em;
}

.btn-gray {
  background-color: $gray-light;
  color: #fff;
}

.btn-uppercase {
  text-transform: uppercase;
}


.navbar {
  margin-bottom: 0;
}


.nav-pills > li.active > a {
  color: $brand-primary;
}
.nav-pills > li.active > a.budget {
  color: #fff;
}
#budget-tabs {
  margin-bottom: 1em;
}
.btn-padding-horizontal-lg {
  padding-left: 2em;
  padding-right: 2em;
}
.btn-group-vertical-margin {
  margin-top: 1em;
  margin-bottom: 1em;
}

.form-horizontal .control-label {
  @media (min-width: 768px) {
    &.budget-month {
      text-align: center;

    }
  }
}

@import "_mobile";
@import "node_modules/eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css";


.margin-bottom-sm {

  margin-bottom: 1em;

  // @media (min-width: $screen-sm-min) {
  //   margin-bottom: 1em;
  // }
  @media (min-width: $screen-md-min) {
    margin-bottom: inherit;
  }
  @media (min-width: $screen-lg-min) {
    margin-bottom: inherit;
  }
}
