// Add anything custom here -- mixins, variable overrides.
// by adding things here instead of overriding existing .LESS files, it makes merging changes on product updates easier
//

.ticketRecap-tile {
  margin: auto;
  display: block;
  /*background-color: #57889c;*/
  background-color: white;
  width: 120px;
  height: 120px;
  cursor: pointer;
  border: 1px solid #C5C5C5;
  text-decoration: none;
  color: white;
  font-weight: 400;
  font-size: 65px;
  text-align: center;
  padding-top: 10px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  position: relative;
}
.ticketTitle {
  color: #4c4f53;
  font-size: 15px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: auto;
  height: 80px;
  display: block;
  text-align: center;
}

.tabellaTicket {
  margin: 20px;
}
